<template>
  <full-view :title="$t('order.details')">
    <div class="overflow-y-auto pa-5" :style="getViewHeightStyle(36)">
      <v-card>
        <order-details
          v-model="order"
          :history="history"
          :support="support"
          :customer="customer"
          :driver="driver"
          :style="getViewHeightStyle(76)"
        />
      </v-card>
    </div>
  </full-view>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FullView from "@/common/layouts/FullView";
import SectionHeader from "@/common/components/SectionHeader";
import OrderDetails from "@/views/orders/OrderDetails";

export default {
  name: "Order",
  components: { FullView, SectionHeader, OrderDetails },
  props: {
    history: Boolean,
    customer: Boolean,
    driver: Boolean,
    support: Boolean,
  },
  data() {
    return {
      order: null,
    };
  },
  mounted() {
    this.order = this.getOrderByUuid(this.getUuid);
    if (!this.order) {
      this.loadOrderByUuid(this.getUuid).then(() => {
        this.order = this.getOrderByUuid(this.getUuid);
        console.log("order:", this.order);
      });
    }
  },
  computed: {
    ...mapGetters("orders", ["getOrderByUuid"]),

    getUuid() {
      return this.$route.params.uuid;
    },
  },
  methods: {
    ...mapActions("orders", ["loadOrderByUuid"]),

    getAddressString(a) {
      if (!a) {
        return "";
      }

      return `${a.title} / ${a.buildingNumber} ${a.street}, ${a.area} (
        ${a.latitude.toLocaleString({ minimumFractionDigits: 5 })} -
        ${a.longitude.toLocaleString({ minimumFractionDigits: 5 })})`;
    },
  },
};
</script>
